<template>
  <v-layout width="100%">
    <v-app-bar app height="124" class="align-start elevation-1">
      <v-col cols="12" class="py-0">
        <v-row dense align="center">
          <v-col cols="auto" class="d-flex d-lg-none pa-0">
            <!--Platzhalter für mobile Menu-->
            <div style="width: 25px"></div>
          </v-col>
          <v-col cols="auto">
            <v-btn icon x-large @click="$router.go(-1)">
              <v-icon x-large>mdi-chevron-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-toolbar-title>
              Übersicht Bestellung
            </v-toolbar-title>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-col>
    </v-app-bar>
    <v-container fluid class="px-0">
      <v-col cols="11" xl="8" lg="9" md="11" class="mx-auto px-0">
        <!--Hauptdaten-->
        <v-row dense class="align-center justify-center">
          <v-card tile outlined class="flex-grow-1">
            <v-col cols="12">
              <v-card-title class="px-2">
                <v-row dense>
                  <v-col cols="12">
                    <v-toolbar-title>
                      Bestellung
                    </v-toolbar-title>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider inset></v-divider>
              <v-card-text class="px-2 subtitle-2">
                <v-row dense align="start">
                  <v-col cols="12">
                    <v-row dense>
                      <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                        Bestellung erfasst
                      </v-col>
                      <v-col cols="6">
                        <span v-if="dataset.bestellt_am">
                          {{
                            "am " +
                              new Date(dataset.bestellt_am).toLocaleDateString("de-DE", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                              })
                          }}
                        </span>
                        <span v-if="dataset.bestellt_durch">
                          {{ "durch " + dataset.bestellt_durch }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row dense align="center">
                      <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                        Artikelnummer
                      </v-col>
                      <v-col cols="5" v-if="dataset.Artikelnummer">
                        {{ dataset.Artikelnummer }}
                      </v-col>
                      <v-col cols="1" v-if="dataset.Artikelnummer">
                        <v-btn icon outlined :to="linkShowArticle"><v-icon>mdi-tshirt-crew-outline</v-icon></v-btn>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                        Bestellmenge
                      </v-col>
                      <v-col cols="6">
                        <span v-if="dataset.Bestellmenge">
                          {{ dataset.Bestellmenge + " " + dataset.Einheit }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row dense v-if="checkCurrentUser">
                      <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                        EK netto
                      </v-col>
                      <v-col cols="6">
                        <span v-if="dataset.EK_netto">
                          {{ dataset.EK_netto + " €" }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row dense align="center">
                      <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                        Hersteller
                      </v-col>
                      <v-col cols="5" v-if="dataset.Hersteller">
                        {{ dataset.Hersteller }}
                      </v-col>
                      <v-col cols="1" v-if="dataset.Hersteller">
                        <v-btn icon outlined :to="linkShowManufacturer"><v-icon>mdi-factory</v-icon></v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-divider inset class="my-3"></v-divider>
                <v-row dense align="start">
                  <v-col cols="12">
                    <v-row dense>
                      <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                        <span v-if="dataset.Lieferzeitraum">Lieferzeitraum</span>
                        <span v-if="dataset.Lieferdatum">Lieferdatum</span>
                      </v-col>
                      <v-col cols="6">
                        <span v-if="dataset.Lieferzeitraum">
                          {{ dataset.Lieferzeitraum }}
                        </span>
                        <span v-if="dataset.Lieferdatum">
                          {{ dataset.Lieferdatum }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-card>
        </v-row>
        <!--Artikeleigenschaften und Beschreibung-->
        <v-row dense class="align-center justify-center pt-5" v-if="dataset.wurde_zugestellt === 1">
          <v-card tile outlined class="flex-grow-1">
            <v-col cols="12">
              <v-card-title class="px-2">
                <v-row dense>
                  <v-col cols="12">
                    <v-toolbar-title>
                      Zustellung
                    </v-toolbar-title>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider inset></v-divider>
              <v-card-text class="px-2 subtitle-2">
                <v-row dense align="start">
                  <v-col cols="12">
                    <v-row dense>
                      <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                        Zustellung erfasst
                      </v-col>
                      <v-col cols="6">
                        <span v-if="dataset.zugestellt_am">
                          {{ "am " + dataset.zugestellt_am }}
                        </span>
                        <span v-if="dataset.zugestellt_durch">
                          {{ "durch " + dataset.zugestellt_durch }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-card>
        </v-row>
        <!--Artikelhistorie-->
        <v-row dense class="align-center justify-center pt-5">
          <v-card tile outlined class="flex-grow-1">
            <v-col cols="12">
              <v-card-title class="px-2">
                <v-row dense>
                  <v-col cols="12">
                    <v-toolbar-title>
                      Vorgänge
                    </v-toolbar-title>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider inset></v-divider>
              <v-card-text class="px-2 subtitle-2">
                <v-row dense>
                  <v-col cols="12">
                    <v-data-table
                      height="calc(48px + (48px * 5))"
                      fixed-header
                      hide-default-footer
                      :items="datasetEvents"
                      item-key="Artikelvorgang_ID"
                      :items-per-page="5"
                      :page.sync="page"
                      @page-count="pageCount = $event"
                      :headers="datasetEventsHeaders"
                      mobile-breakpoint="300"
                      style="width: 100%;"
                    >
                      <template #[`item.Vorgang`]="{ item }">
                        <span class="font-weight-bold"> {{ item.Vorgang }}</span>
                      </template>
                      <template #[`item.Datum`]="{ item }">
                        <span>{{
                          new Date(item.Datum).toLocaleDateString("de-DE", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                          }) + " Uhr"
                        }}</span>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider inset></v-divider>
              <v-card-actions>
                <v-row dense align="center">
                  <v-col cols="12">
                    <v-pagination color="grey darken-2" v-model="page" :length="pageCount"></v-pagination>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
          </v-card>
        </v-row>
      </v-col>
    </v-container>
  </v-layout>
</template>

<script>
export default {
  created() {
    this.initialize();
  },

  mounted() {
    this.initialize();
  },

  data: () => ({
    dataset: {},
    hiddenColumns: ["Bestellvorgang_ID"],
    datasetEventsHeaders: [],
    datasetEvents: [],
    page: 1,
    pageCount: 0,
  }),

  computed: {
    checkCurrentUser() {
      const user = localStorage.getItem("user");

      if (user === "Aline" || user === "Admin") {
        return true;
      } else return false;
    },

    linkShowManufacturer() {
      if (this.dataset.Hersteller) {
        return `/wws/database/manufacturers/dataset/show/${this.dataset.Hersteller_ID}`;
      }
    },

    linkShowArticle() {
      if (this.dataset.Artikelnummer) {
        return `/wws/database/articles/dataset/show/${this.dataset.Artikel_ID}`;
      }
    },
  },

  methods: {
    async initialize() {
      const response = await fetch(`/api/${this.$route.meta.request}/dataset/show/${this.$route.params.id}`);
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }

      const alignmentHeaders = ["start", "end", "center"];
      const dividerHeaders = [true, true, false];

      this.datasetEventsHeaders = Object.keys(this.datasetEvents[0])
        .filter((it) => this.hiddenColumns.indexOf(it) === -1)
        .map((it, i) => {
          return {
            text: it,
            value: it,
            sortable: false,
            filterable: false,
            align: alignmentHeaders[i],
            divider: dividerHeaders[i],
            class: "text--primary text-uppercase",
          };
        });
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month] = date.split("-");
      return `${month} / ${year}`;
    },
  },
};
</script>
